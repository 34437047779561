import { useNavigate, useRevalidator, useSearchParams } from '@remix-run/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { $path } from 'remix-routes';
import useSWR, { mutate } from 'swr';
import { match } from 'ts-pattern';

import {
  type DtoAssignedMemberRow,
  type DtoCourseStackInfo,
  type DtoGame,
  type DtoGroupEnrollmentDetail,
  type DtoSingleGamePackResponse,
  EnumsGamePackUGCCreationStatus,
} from '@lp-lib/api-service-client/public';

import { gamePackToEnrollmentTarget } from '../../../../../app/components/GamePack/utils';
import { useLearningAnalytics } from '../../../../analytics/learning';
import { apiService } from '../../../../services/api-service';
import { fromDTOGamePack, fromDTOGames } from '../../../../utils/api-dto';
import { SegmentedControl } from '../../../common/SegmentedControl';
import { type Option } from '../../../common/Utilities';
import { Overworld } from '../../../GameV2/Overworld';
import { Loading } from '../../../Loading';
import { isCreationDone, pullCourseCreationStatus } from '../../utils';
import { SortIcon } from '../Analytics/SortIcon';
import { PageHeader } from '../Components/PageHeader';
import { type EnrollmentTarget } from '../Modals/CourseAssignmentModal';
import { useEnrollmentActions } from '../useCourseActions';
import { AssignedGroupRow } from './AssignedGroupRow';
import {
  AssignedMemberRow,
  useNavigateToLearnerProfile,
} from './AssignedMemberRow';
import { CourseStacksSection } from './CourseDetailStackView';

function useCourseDetailSorting() {
  const [searchParams, setSearchParams] = useSearchParams();

  const sortState = useMemo(() => {
    const sortField = searchParams.get('sort');
    const sortDirection = searchParams.get('dir');

    return { sortField, sortDirection };
  }, [searchParams]);

  const toggleSort = useCallback(
    (field: string) => {
      let newDirection: string | null = 'asc';

      if (sortState.sortField === field) {
        if (sortState.sortDirection === 'asc') {
          newDirection = 'desc';
        } else if (sortState.sortDirection === 'desc') {
          newDirection = null;
        } else {
          newDirection = 'asc';
        }
      }

      setSearchParams(
        (params) => {
          const newParams = new URLSearchParams(params);

          if (newDirection === null) {
            newParams.delete('sort');
            newParams.delete('dir');
          } else {
            newParams.set('sort', field);
            newParams.set('dir', newDirection);
          }

          return newParams;
        },
        { replace: true }
      );
    },
    [setSearchParams, sortState]
  );

  return {
    sortField: sortState.sortField,
    sortDirection: sortState.sortDirection,
    toggleSort,
  };
}

interface CourseDetailsSortState {
  sortField: string | null;
  sortDirection: string | null;
}

interface CourseDetailsPageProps {
  pack: DtoSingleGamePackResponse['gamePack'];
  groups: DtoGroupEnrollmentDetail[];
  stacks: DtoCourseStackInfo[];
  games?: DtoGame[];
  sortState?: CourseDetailsSortState;
}

const CourseDetailActionsBox = ({
  onAssign,
  onEdit,
  onPreview,
  customAction,
}: {
  onAssign: () => void;
  onEdit: () => void;
  onPreview: () => void;
  customAction?: React.ReactNode;
}) => {
  return (
    <div className='w-86 max-w-[18rem] bg-main-layer rounded-xl p-2 pt-7 shadow-xl'>
      <p className='text-center text-xl font-bold text-tertiary mb-4'>
        Utilize This Course
      </p>
      {customAction ?? (
        <div className='flex flex-col gap-2.5'>
          <button
            type='button'
            className='btn-delete w-full h-10 rounded flex items-center justify-center gap-2 text-white text-sms'
            onClick={onAssign}
          >
            Assign Course
          </button>

          <button
            type='button'
            className='btn-secondary w-full h-10 rounded flex items-center justify-center gap-2 text-white text-sms'
            onClick={onEdit}
          >
            Edit Course
          </button>

          <button
            type='button'
            className='btn-secondary w-full h-10 rounded flex items-center justify-center gap-2 text-white text-sms'
            onClick={onPreview}
          >
            Preview Course
          </button>
        </div>
      )}
    </div>
  );
};

const MobileCourseDetailActionsBox = ({
  onAssign,
  onPreview,
  customAction,
}: {
  onAssign: () => void;
  onPreview: () => void;
  customAction?: React.ReactNode;
}) => {
  return (
    <div className='w-full bg-main-layer rounded-t-xl pt-3'>
      <p className='text-center font-bold text-tertiary mb-4 text-sms'>
        Utilize This Course
      </p>
      {customAction ?? (
        <div className='flex flex-col gap-2.5 px-4 pb-4'>
          <button
            type='button'
            className='btn-delete w-full h-10 rounded flex items-center justify-center gap-2 text-white'
            onClick={onAssign}
          >
            Assign Course
          </button>
          <button
            type='button'
            className='btn-secondary w-full h-10 rounded flex items-center justify-center gap-2 text-white'
            onClick={onPreview}
          >
            Preview Course
          </button>
        </div>
      )}
    </div>
  );
};

type TabOption = 'groups' | 'members' | 'stacks' | 'external';

export const CourseDetailsPage = ({
  pack,
  groups,
  stacks,
  games,
}: CourseDetailsPageProps) => {
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const navigateToLearnerProfile = useNavigateToLearnerProfile();

  const { sortField, sortDirection, toggleSort } = useCourseDetailSorting();

  const {
    data: membersData,
    error: membersError,
    isLoading: membersLoading,
    isValidating,
  } = useSWR(
    ['/learning/courses', pack.id, 'members', sortField, sortDirection],
    async () => {
      const response = await apiService.learning.getCourseAssignedMembers(
        pack.id,
        {
          sortField: sortField || undefined,
          sortDirection: sortDirection || undefined,
        }
      );

      return {
        members:
          response.data.members?.map((member) => ({
            ...member,
            assignedOn: new Date(member.assignedOn).toLocaleDateString(
              'en-US',
              {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
              }
            ),
          })) ?? [],
        indirectMembers:
          response.data.indirectMembers?.map((member) => ({
            ...member,
            assignedOn: new Date(member.assignedOn).toLocaleDateString(
              'en-US',
              {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
              }
            ),
          })) ?? [],
        externalLearners:
          response.data.externalLearners?.map((member) => ({
            ...member,
            assignedOn: new Date(member.assignedOn).toLocaleDateString(
              'en-US',
              {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
              }
            ),
          })) ?? [],
      };
    },
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      keepPreviousData: true,
    }
  );

  const assignedMembers = membersData?.members || [];
  const indirectlyAssignedMembers = membersData?.indirectMembers || [];
  const externalLearners = membersData?.externalLearners || [];

  const tabOptions: Option<TabOption>[] = [
    { label: 'Members', value: 'members' },
    { label: 'Groups', value: 'groups' },
    { label: 'Stacks', value: 'stacks' },
  ];

  if (externalLearners && externalLearners.length > 0) {
    if (!tabOptions.some((t) => t.value === 'external')) {
      tabOptions.splice(1, 0, { label: 'External', value: 'external' });
    }
  }

  const [activeTab, setActiveTab] = useState<Option<TabOption>>(tabOptions[0]);

  const lobbyBackgroundExists = Boolean(
    pack.marketingSettings?.lobbyBackground?.media?.url
  );

  interface MergedAssignedMember extends DtoAssignedMemberRow {
    isCourseAssignment: boolean;
    isStackAssignment: boolean;
  }

  const mergedMembersMap = new Map<string, MergedAssignedMember>();

  assignedMembers?.forEach((member) => {
    mergedMembersMap.set(member.id, {
      ...member,
      isCourseAssignment: true,
      isStackAssignment: false,
    });
  });

  indirectlyAssignedMembers?.forEach((member) => {
    if (mergedMembersMap.has(member.id)) {
      // Member already exists (assigned directly), so add stack info.
      const existing = mergedMembersMap.get(member.id);
      if (!existing) return;
      // unify group names
      const uniqueGroupNames = new Set([...existing.groups, ...member.groups]);
      mergedMembersMap.set(member.id, {
        ...existing,
        group: Array.from(uniqueGroupNames).join(', '),
        isCourseAssignment: existing.isCourseAssignment,
        isStackAssignment: true,
      });
    } else {
      mergedMembersMap.set(member.id, {
        ...member,
        isCourseAssignment: false,
        isStackAssignment: true,
      });
    }
  });
  const allAssignedMembers = Array.from(mergedMembersMap.values());

  const { handleAssign, handleEdit } = useEnrollmentActions();
  const analytics = useLearningAnalytics();

  const handleEditGroup = (groupId: string) => {
    navigate($path('/learning/admin/groups/:id', { id: groupId }));
  };

  const handleRemoveGroup = async (groupId: string) => {
    await apiService.learning.unassignGroupFromCourse(pack.id, groupId);
    mutate(['/learning/courses', pack.id, 'members', sortField, sortDirection]);
    revalidator.revalidate();
  };

  function handleAssignAndRevalidate(target: EnrollmentTarget) {
    handleAssign(target, () => {
      mutate([
        '/learning/courses',
        pack.id,
        'members',
        sortField,
        sortDirection,
      ]);
      revalidator.revalidate();
    });
  }
  const pck = fromDTOGamePack(pack);
  const gms = fromDTOGames(games || []);

  const handleUnassign = async (userId: string) => {
    try {
      await apiService.learning.unassignMemberFromCourse(pack.id, userId);

      mutate([
        '/learning/courses',
        pack.id,
        'members',
        sortField,
        sortDirection,
      ]);
      revalidator.revalidate();

      toast.success(
        <div className='flex items-start'>
          <div className='ml-3'>
            <p className='text-sm text-white'>
              Successfully unassigned member from course
            </p>
          </div>
        </div>,
        { autoClose: 3000 }
      );
    } catch (error) {
      toast.error(
        <div className='flex items-start'>
          <div className='ml-3'>
            <p className='text-sm text-white'>
              Failed to unassign member from course
            </p>
          </div>
        </div>,
        { autoClose: 5000 }
      );
    }
  };

  const [creationStatus, setCreationStatus] = useState(
    pack.ugcSettings?.creationStatus
  );

  useEffect(() => {
    if (isCreationDone(creationStatus)) return;
    const aborter = new AbortController();
    pullCourseCreationStatus(
      pack.id,
      (updatedPack) => {
        setCreationStatus(updatedPack.ugcSettings?.creationStatus);
        // The cover image is not used here?!
      },
      aborter.signal,
      10000
    ).catch(console.error);
    return () => {
      aborter.abort();
    };
  }, [creationStatus, pack.cover?.id, pack.id]);

  const customAction = match(creationStatus)
    .with(
      EnumsGamePackUGCCreationStatus.GamePackUGCCreationStatusQueued,
      () => (
        <Loading text='Creation Queued' containerClassName='text-sms mb-2' />
      )
    )
    .with(
      EnumsGamePackUGCCreationStatus.GamePackUGCCreationStatusInProgress,
      () => (
        <Loading
          text='Creation In Progress'
          containerClassName='text-sms mb-2'
        />
      )
    )
    .with(
      EnumsGamePackUGCCreationStatus.GamePackUGCCreationStatusFailed,
      () => (
        <div className='text-red-002 text-sms mb-2 text-center'>
          Creation Failed
        </div>
      )
    )
    .otherwise(() => null);

  interface SortableHeaderProps {
    field: string;
    label: string;
    centerAlign?: boolean;
    sortField: string | null;
    sortDirection: string | null;
    toggleSort: (field: string) => void;
    disabled?: boolean;
  }

  function SortableHeader({
    field,
    label,
    centerAlign = false,
    sortField,
    sortDirection,
    toggleSort,
    disabled = false,
  }: SortableHeaderProps) {
    let direction: 'asc' | 'desc' | null = null;

    if (sortField === field) {
      if (sortDirection === 'asc') {
        direction = 'asc';
      } else if (sortDirection === 'desc') {
        direction = 'desc';
      }
    }

    return (
      <div
        className={`flex items-center gap-1 cursor-pointer select-none ${
          centerAlign ? 'justify-center w-full' : ''
        }`}
        onClick={() => toggleSort(field)}
      >
        {label}
        <button
          type='button'
          className='text-xs p-1 hover:bg-lp-gray-006 rounded'
          disabled={disabled}
        >
          <SortIcon direction={direction} className='w-3.5 h-3.5' />
        </button>
      </div>
    );
  }

  return (
    <div className='flex flex-col w-full text-white max-w-screen-2xl mx-auto relative h-full'>
      {/* Header section */}
      <div className='hidden md:flex relative z-10 w-full px-4 md:px-8 pt-4 md:pt-8 flex-col gap-1 pb-4'>
        <PageHeader
          title='Courses'
          path={$path('/learning/admin/my-courses')}
        />
        <h1 className='text-2xl md:text-3xl font-bold'>{pack.name}</h1>
      </div>

      {/* Scrollable content container */}
      <div className='flex-1 overflow-y-auto pb-40 md:pb-0'>
        {/* Hero Section */}
        <div className='relative w-full h-[380px] md:h-[550px] md:px-7 rounded-xl'>
          {/* note(jose): When there is no lobby background, the overworld doesn't render a placeholder image. This is 
            used instead. */}
          {!lobbyBackgroundExists && (
            <div className='w-full h-full md:h-full object-cover md:rounded-2xl bg-black' />
          )}
          <div className='absolute inset-0 flex md:items-center md:justify-center md:px-6'>
            <div className='w-full'>
              <Overworld
                pack={pck}
                games={gms}
                displayOptions={{
                  showLpLogo: false,
                  hideStartBounce: true,
                  topSpacing: 'h-0',
                  overflow: 'overflow-hidden',
                  maxNodes: 4,
                  roundedBg: true,
                }}
                isPreview
              />
            </div>
          </div>
          <div className='hidden md:block absolute bottom-0 right-0 translate-y-1/2 translate-x-[-10%] mr-2 z-20'>
            <CourseDetailActionsBox
              onAssign={() => {
                analytics.trackCourseDetailActionClicked({ type: 'assign' });
                handleAssignAndRevalidate(gamePackToEnrollmentTarget(pack));
              }}
              onEdit={() => {
                analytics.trackCourseDetailActionClicked({ type: 'edit' });
                handleEdit(pack.id);
              }}
              onPreview={() => {
                analytics.trackCourseDetailActionClicked({ type: 'preview' });
                window.open(`/game-packs/${pack.id}/preview`, '_blank');
              }}
              customAction={customAction}
            />
          </div>
        </div>
        {/* Description section */}
        <div className='px-4 md:px-8 py-2 md:py-6 2xl:max-w-5xl xl:max-w-4xl lg:max-w-3xl md:max-w-2xl sm:max-w-full max-w-xl'>
          <h1 className='text-2xl md:text-3xl font-bold pb-2 capitalize'>
            {pack.name}
          </h1>
          <span className='text-sms'>
            {pack.description?.length > 0 ? (
              <>{pack.description}</>
            ) : (
              <>This course has no description.</>
            )}
          </span>
        </div>

        {/* Groups or Members section */}
        <div className='px-4 md:px-8 py-6 md:py-10'>
          {/* Segmented Control */}
          <div className='w-full flex justify-center md:justify-start pb-4'>
            <div className='w-54'>
              <SegmentedControl
                options={tabOptions}
                value={activeTab}
                onChange={(value) => setActiveTab(value)}
                styles={{
                  glider: 'bg-lp-gray-006 rounded-lg',
                  track: 'rounded-xl p-1 border border-secondary',
                  option: 'px-4 py-2 transition-colors text-sms',
                  selectedOption: 'text-white',
                  unselectedOption: 'text-gray-400 hover:text-white',
                }}
              />
            </div>
          </div>

          {activeTab.value === 'stacks' ? (
            <CourseStacksSection stacks={stacks} />
          ) : activeTab.value === 'groups' ? (
            <>
              <h2 className='text-xl md:text-2xl font-bold text-white mb-4 md:mb-6 hidden md:block'>
                Groups ({groups.length})
              </h2>
              {groups.length > 0 ? (
                <>
                  {/* Header row for groups */}
                  <div className='hidden md:flex w-full px-4 py-2 items-center text-gray-400 text-xs uppercase font-medium'>
                    <div className='w-1/5'>Group Name</div>
                    <div className='w-1/5'>Assigned By</div>
                    <div className='w-1/5'>Assigned On</div>
                    <div className='w-1/5'>Members</div>
                    <div className='w-12'></div>
                  </div>
                  <div className='flex flex-col gap-2.5 mt-2'>
                    {groups.map((group) => (
                      <AssignedGroupRow
                        key={group.groupId}
                        group={group}
                        onDelete={handleRemoveGroup}
                        onEdit={handleEditGroup}
                      />
                    ))}
                  </div>
                </>
              ) : (
                <div className='bg-[#17171A] border border-lp-gray-003 rounded-lg p-6 text-center text-gray-400 mt-2'>
                  <div className='mb-2'>
                    No groups assigned to this course yet
                  </div>
                  <div className='text-sm'>
                    Assign this course to groups to make it available to group
                    members
                  </div>
                </div>
              )}
            </>
          ) : activeTab.value === 'external' ? (
            <>
              <h2 className='text-xl md:text-2xl font-bold text-white mb-4 md:mb-6 hidden md:flex items-center justify-between'>
                External Learners ({externalLearners?.length || 0})
                {membersLoading && (
                  <span className='ml-2'>
                    <Loading
                      text='Loading...'
                      containerClassName='inline-flex'
                      imgClassName='w-5 h-5'
                    />
                  </span>
                )}
              </h2>
              {externalLearners && externalLearners.length > 0 && (
                /* Header row for external learners */
                <div className='hidden md:flex w-full px-4 py-2 items-center text-gray-400 text-xs uppercase font-medium'>
                  <div className='md:w-1/5 flex-1 pr-1'>
                    <SortableHeader
                      field='name'
                      label='Name/Email'
                      sortField={sortField}
                      sortDirection={sortDirection}
                      toggleSort={toggleSort}
                      disabled={isValidating}
                    />
                  </div>
                  <div className='md:w-1/5 flex-1'>First Seen</div>
                  <div className='flex-1'>Progress</div>
                  <div className='md:w-1/5 flex-1'>
                    <SortableHeader
                      field='assessmentScore'
                      label='Assessment Score'
                      sortField={sortField}
                      sortDirection={sortDirection}
                      toggleSort={toggleSort}
                      disabled={isValidating}
                    />
                  </div>
                </div>
              )}
              <div className='flex flex-col gap-2.5 mt-2 relative'>
                {membersError ? (
                  <div className='w-full py-8 text-center text-red-500'>
                    Error loading external learners. Please try again.
                  </div>
                ) : externalLearners?.length > 0 ? (
                  externalLearners?.map((member) => (
                    <AssignedMemberRow
                      key={member.id}
                      uid={member.id}
                      name={
                        member.name.trim() === '' ? member.email : member.name
                      }
                      group=''
                      assignedOn={member.assignedOn}
                      addedBy='N/A'
                      completed={member.completed}
                      directProgress={member.progressPct}
                      isDirect={false}
                      isIndirect={false}
                      isExternal={true}
                      progression={member.progression ?? undefined}
                    />
                  ))
                ) : (
                  <div className='bg-[#17171A] border border-lp-gray-003 rounded-lg p-6 text-center text-gray-400 mt-2'>
                    <div className='mb-2'>
                      No external learners have accessed this course yet
                    </div>
                    <div className='text-sm'>
                      External learners appear here when they access the course
                      from an external LMS
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <h2 className='text-xl md:text-2xl font-bold text-white mb-4 md:mb-6 hidden md:flex items-center'>
                Assigned Members ({allAssignedMembers.length})
                {membersLoading && (
                  <span className='ml-2'>
                    <Loading
                      text='Loading...'
                      containerClassName='inline-flex'
                      imgClassName='w-5 h-5'
                    />
                  </span>
                )}
              </h2>
              {allAssignedMembers.length > 0 && (
                /* Header row for members */
                <div className='hidden md:flex w-full px-4 py-2 items-center text-gray-400 text-xs uppercase font-medium'>
                  <div className='md:w-1/5 flex-1 pr-1'>
                    <SortableHeader
                      field='name'
                      label='Name'
                      sortField={sortField}
                      sortDirection={sortDirection}
                      toggleSort={toggleSort}
                    />
                  </div>
                  <div className='md:w-1/5 flex-1'>Group Assignment</div>
                  <div className='md:w-1/5 flex-1'>Assigned On</div>
                  <div className='md:w-1/5 flex-1'>Assigned By</div>
                  <div className='flex-1'>Progress</div>
                  <div className='md:w-1/5 flex-1'>
                    <SortableHeader
                      field='assessmentScore'
                      label='Assessment Score'
                      sortField={sortField}
                      sortDirection={sortDirection}
                      toggleSort={toggleSort}
                    />
                  </div>
                  <div className='w-12'></div>
                </div>
              )}
              <div className='flex flex-col gap-2.5 mt-2 relative'>
                {membersError ? (
                  <div className='w-full py-8 text-center text-red-500'>
                    Error loading members. Please try again.
                  </div>
                ) : allAssignedMembers.length > 0 ? (
                  allAssignedMembers.map((member) => (
                    <AssignedMemberRow
                      key={member.id}
                      uid={member.id}
                      name={
                        member.name.trim() === '' ? member.email : member.name
                      }
                      group={member.group}
                      assignedOn={member.assignedOn}
                      addedBy={member.addedBy}
                      completed={member.completed}
                      progression={member.progression ?? undefined}
                      directProgress={member.progressPct}
                      isDirect={member.isCourseAssignment}
                      isIndirect={member.isStackAssignment}
                      onDirectUnassign={() => handleUnassign(member.id)}
                      onIndirectUnassign={() =>
                        navigate(
                          $path('/learning/admin/my-courses/stacks/:id', {
                            id: member.objectId,
                          })
                        )
                      }
                      onClick={() => navigateToLearnerProfile(member.id)}
                    />
                  ))
                ) : (
                  <div className='bg-[#17171A] border border-lp-gray-003 rounded-lg p-6 text-center text-gray-400 mt-2'>
                    <div className='mb-2'>
                      No members assigned to this course yet
                    </div>
                    <div className='text-sm'>
                      Assign this course to members or groups to get started
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {/* MOBILE CTA  */}
      <div className='md:hidden fixed bottom-0 left-0 w-full bg-black border-t border-secondary z-30 rounded-t-xl'>
        <MobileCourseDetailActionsBox
          onAssign={() => {
            analytics.trackCourseDetailActionClicked({ type: 'assign' });
            handleAssignAndRevalidate(gamePackToEnrollmentTarget(pack));
          }}
          onPreview={() => {
            analytics.trackCourseDetailActionClicked({ type: 'preview' });
            window.open(`/game-packs/${pack.id}/overworld`, '_blank');
          }}
          customAction={customAction}
        />
      </div>
    </div>
  );
};
